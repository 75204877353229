import moment from "moment";
import { createCrudActions } from '../utils/crudGenerator';

const formatWorkorders = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
        date: item.date && moment(item.date),
        work: item.work && item.work.map(work => ({
            ...work,
            startTime: moment(work.startTime),
            endTime: moment(work.endTime)
        }))
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchWorkorders,
    create: createWorkorder,
    update: updateWorkorder,
    delete: deleteWorkorders
} = createCrudActions('WORKORDER', "Parte de trabajo", formatWorkorders);
