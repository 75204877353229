import { ControlOutlined } from "@ant-design/icons";
import { ClockCircleOutlined } from "@ant-design/icons";
import { CarOutlined, HomeOutlined, LoginOutlined, LogoutOutlined, TeamOutlined, ToolOutlined, UserOutlined, QrcodeOutlined, CalendarOutlined } from "@ant-design/icons";
import React from 'react';
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Dashboard",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.operator.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.operator.role],
  },

  equipments: {
    key: "equipments",
    slug: "equipments",
    to: "/equipments",
    title: "Equipos",
    icon: <CarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  workorders: {
    key: "workorders",
    slug: "workorders",
    to: "/workorders",
    title: "Parte de trabajos",
    icon: <ToolOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.operator.role],
  },

  singups: {
    key: "singups",
    slug: "singups",
    to: "/singups",
    title: "Fichajes",
    icon: <TeamOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  singupscalendar: {
    key: "singupscalendar",
    slug: "singupscalendar",
    to: "/singupscalendar",
    title: "Calendario de Fichajes",
    icon: <CalendarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.operator.role],
  },

  clockin: {
    key: "clockin",
    slug: "clockin",
    to: "/clockin",
    title: "Fichar",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.operator.role],
  },

  qr: {
    key: "qr",
    slug: "qr",
    to: "/qr",
    title: "QR fichar",
    icon: <QrcodeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role],
  },

  petitions: {
    key: "petitions",
    slug: "petitions",
    to: "/petitions",
    title: "Peticiones",
    icon: <ClockCircleOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  configurations: {
    key: "configurations",
    slug: "configurations",
    to: "/configurations",
    title: "Configuración",
    icon: <ControlOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.operator.role],
  },
  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Log out",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.operator.role],
  },
};
