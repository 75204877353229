import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  equipments,
  equipmentsForm,
  workorders,
  workordersForm,
  singups,
  singupsForm,
  petitions,
  petitionsForm,
  configurations,
  configurationsForm,
} from "./Reducers";

export default combineReducers({
  configurations,
  configurationsForm,
  petitions,
  petitionsForm,
  singups,
  singupsForm,
  workorders,
  workordersForm,
  equipments,
  equipmentsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
