/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USER_FORM = "OPEN_USER_FORM";
export const CLOSE_USER_FORM = "CLOSE_USER_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* CONFIGURATION */
export const SET_CONFIGURATION_LIST = "SET_CONFIGURATION_LIST";
export const ADD_NEW_CONFIGURATION = "ADD_NEW_CONFIGURATION";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";
export const OPEN_CONFIGURATION_FORM = "OPEN_CONFIGURATION_FORM";
export const CLOSE_CONFIGURATION_FORM = "CLOSE_CONFIGURATION_FORM";
export const EDIT_SELECTED_CONFIGURATION = "EDIT_SELECTED_CONFIGURATION";
export const CONFIGURATION_FORM_TOOGLE_LOADING = "CONFIGURATION_FORM_TOOGLE_LOADING";
/* <---- CONFIGURATION ----> */

/* PETITION */
export const SET_PETITION_LIST = "SET_PETITION_LIST";
export const ADD_NEW_PETITION = "ADD_NEW_PETITION";
export const UPDATE_PETITION = "UPDATE_PETITION";
export const DELETE_PETITION = "DELETE_PETITION";
export const OPEN_PETITION_FORM = "OPEN_PETITION_FORM";
export const CLOSE_PETITION_FORM = "CLOSE_PETITION_FORM";
export const EDIT_SELECTED_PETITION = "EDIT_SELECTED_PETITION";
export const PETITION_FORM_TOOGLE_LOADING = "PETITION_FORM_TOOGLE_LOADING";
/* <---- PETITION ----> */

/* SINGUP */
export const SET_SINGUP_LIST = "SET_SINGUP_LIST";
export const ADD_NEW_SINGUP = "ADD_NEW_SINGUP";
export const UPDATE_SINGUP = "UPDATE_SINGUP";
export const DELETE_SINGUP = "DELETE_SINGUP";
export const OPEN_SINGUP_FORM = "OPEN_SINGUP_FORM";
export const CLOSE_SINGUP_FORM = "CLOSE_SINGUP_FORM";
export const EDIT_SELECTED_SINGUP = "EDIT_SELECTED_SINGUP";
export const SINGUP_FORM_TOOGLE_LOADING = "SINGUP_FORM_TOOGLE_LOADING";
/* <---- SINGUP ----> */

/* WORKORDER */
export const SET_WORKORDER_LIST = "SET_WORKORDER_LIST";
export const ADD_NEW_WORKORDER = "ADD_NEW_WORKORDER";
export const UPDATE_WORKORDER = "UPDATE_WORKORDER";
export const DELETE_WORKORDER = "DELETE_WORKORDER";
export const OPEN_WORKORDER_FORM = "OPEN_WORKORDER_FORM";
export const CLOSE_WORKORDER_FORM = "CLOSE_WORKORDER_FORM";
export const EDIT_SELECTED_WORKORDER = "EDIT_SELECTED_WORKORDER";
export const WORKORDER_FORM_TOOGLE_LOADING = "WORKORDER_FORM_TOOGLE_LOADING";
/* <---- WORKORDER ----> */

/* EQUIPMENT */
export const SET_EQUIPMENT_LIST = "SET_EQUIPMENT_LIST";
export const ADD_NEW_EQUIPMENT = "ADD_NEW_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const OPEN_EQUIPMENT_FORM = "OPEN_EQUIPMENT_FORM";
export const CLOSE_EQUIPMENT_FORM = "CLOSE_EQUIPMENT_FORM";
export const EDIT_SELECTED_EQUIPMENT = "EDIT_SELECTED_EQUIPMENT";
export const EQUIPMENT_FORM_TOOGLE_LOADING = "EQUIPMENT_FORM_TOOGLE_LOADING";
/* <---- EQUIPMENT ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
