import { createCrudActions } from '../utils/crudGenerator';

const formatEquipments = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchEquipments,
    create: createEquipment,
    update: updateEquipment,
    delete: deleteEquipments
} = createCrudActions('EQUIPMENT', "Equipo", formatEquipments);