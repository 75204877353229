import { createCrudActions } from '../utils/crudGenerator';

const formatConfigurations = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchConfigurations,
    create: createConfiguration,
    update: updateConfiguration,
    delete: deleteConfigurations
} = createCrudActions('CONFIGURATION', "Configuración", formatConfigurations);