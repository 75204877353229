import moment from "moment";
import { createCrudActions } from '../utils/crudGenerator';

const formatPetitions = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
        end: item.end && moment(item.end),
        start: item.start && moment(item.start),
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchPetitions,
    create: createPetition,
    update: updatePetition,
    delete: deletePetitions
} = createCrudActions('PETITION', "Petición", formatPetitions);
